import React, { useContext } from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import Webform from "../../plugins/gatsby-drupal-webform-unida"
import { LanguageContext } from "../context"
import Description from "../components/section/section-description"
import EventsPage from "../components/pages/events-page"

const PageEvents = ({data, location, pageContext }) => {
    const post = data?.nodePageGoel
    console.log("POST,", post)
    const events = data?.allNodeEventGoel
    const form = data?.form
    const { t } = useContext(LanguageContext)
    return(
      <div>
        <Layout
          translationPaths={homePagePaths}
          location={location}
          langcode={post?.langcode}
        >
        <Seo
            title={post?.title}
            lang={post?.langcode}
            description={post?.body?.summary}
            image={post?.relationships?.field_image?.image_style_uri?._653_432}
          />
          <Header
            lang={pageContext?.langcode}
            location={location}
          />
          <EventsPage
            post={post}
            lang={post?.langcode}
            events={events}
            bannerHeight="480px"
            pageContext={pageContext}
           />
          <Footer
            lang={pageContext?.langcode}
            location={location}
          />
        </Layout>
      </div>
  )
}

export const pageQuery = graphql`
    query PageEvents ($drupal_internal__nid: Int, $langcode: String!, $currentDate: Date!){
        nodePageGoel (
            drupal_internal__nid: {eq: $drupal_internal__nid},
            langcode:{eq: $langcode}
            ){
              title
              langcode
              body {
                processed
                value
                summary
              }
              relationships{
                field_image {
                  image_style_uri {
                    _120x120
                    _1440x408
                    _294x192
                    _541x359
                    _653_432
                    _1103x488
                    large
                    medium
                    thumbnail
                    wide
                  }
                }
              }
            }
            allNodeEventGoel (
                filter: {
                  langcode: {eq: $langcode}
                  field_start_time: {gte: $currentDate}
                }
              ) {
                edges {
                  node {
                    title
                    field_subtitle
                    relationships {
                      field_image {
                        image_style_uri {
                          _120x120
                          _1440x408
                          _294x192
                          _541x359
                          _653_432
                          _1103x488
                          large
                          medium
                          thumbnail
                          wide
                        }
                      }
                      field_project {
                        title
                      }
                    }
                    body {
                      processed
                      summary
                    }
                    path{
                      alias
                    }
                    created
                    field_start_time
                    field_end_time
                    field_price_session
                    field_location
                  }
                }
              }
    }
`

export default PageEvents
